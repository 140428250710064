<template>
  <main>
    <Navbar />

    <KoiBanner type="sub" :title="$t('Terms of Service')" />

    <section :class="[$style.sub, $style[$mq]]">
      <h6>{{ $t("Terms of Service") }}</h6>

      <time :class="$style.time"
        >{{ $t("Last Updated:") }}
        {{ $d(new Date("2018-10-01"), "short") }}</time
      >

      <p>
        <strong v-if="/^zh/.test($i18n.locale)"
          >中文翻译仅供参考，所有法律文件以英文版本为准</strong
        >
      </p>

      <p>{{ $t("TERMCONTENT.P[0]") }}</p>

      <p>{{ $t("TERMCONTENT.P[1]") }}</p>

      <p>{{ $t("TERMCONTENT.P[2]") }}</p>

      <p>{{ $t("TERMCONTENT.P[3]") }}</p>

      <h6>{{ $t("Authorized User") }}</h6>

      <p>{{ $t("TERMCONTENT.P[4]") }}</p>

      <p>{{ $t("TERMCONTENT.P[5]") }}</p>

      <p v-html="$t('TERMCONTENT.P[6]')"></p>

      <h6>{{ $t("Disclaimer of Warranty and Limitation of Liability") }}</h6>

      <p>{{ $t("TERMCONTENT.P[7]") }}</p>

      <p>{{ $t("TERMCONTENT.P[8]") }}</p>

      <p>{{ $t("TERMCONTENT.P[9]") }}</p>

      <h6>{{ $t("Proprietary Rights") }}</h6>

      <p>{{ $t("TERMCONTENT.P[10]") }}</p>

      <p>{{ $t("TERMCONTENT.P[11]") }}</p>

      <h6>{{ $t("User Submitted Information") }}</h6>

      <p>{{ $t("TERMCONTENT.P[12]") }}</p>

      <ul>
        <li>{{ $t("TERMCONTENT.P[13]") }}</li>

        <li>{{ $t("TERMCONTENT.P[14]") }}</li>

        <li>{{ $t("TERMCONTENT.P[15]") }}</li>

        <li>{{ $t("TERMCONTENT.P[16]") }}</li>

        <li>{{ $t("TERMCONTENT.P[17]") }}</li>

        <li>{{ $t("TERMCONTENT.P[18]") }}</li>

        <li>{{ $t("TERMCONTENT.P[19]") }}</li>

        <li>{{ $t("TERMCONTENT.P[20]") }}</li>

        <li>{{ $t("TERMCONTENT.P[21]") }}</li>

        <li>{{ $t("TERMCONTENT.P[22]") }}</li>
      </ul>

      <p>{{ $t("TERMCONTENT.P[23]") }}</p>

      <h6>{{ $t("Use of Links") }}</h6>

      <p>{{ $t("TERMCONTENT.P[24]") }}</p>

      <h6>{{ $t("Third Party Content") }}</h6>

      <p>{{ $t("TERMCONTENT.P[25]") }}</p>

      <h6>{{ $t("Financial Assets") }}</h6>

      <p>{{ $t("TERMCONTENT.P[26]") }}</p>

      <h6>{{ $t("Indemnity and Release") }}</h6>

      <p>{{ $t("TERMCONTENT.P[27]") }}</p>

      <h6>{{ $t("Choice of Law; Arbitration") }}</h6>

      <p>{{ $t("TERMCONTENT.P[28]") }}</p>
    </section>

    <Zendesk />

    <KoiFooter />
  </main>
</template>

<script>
import debug from "debug";
import KoiBanner from "@/components/KoiBanner";
import KoiFooter from "@/components/KoiFooter";

import Navbar from "@/components/Navbar";
import Zendesk from "@/components/Zendesk";

const log = debug("koiweb");

export default {
  name: "terms",
  props: {},
  components: {
    KoiBanner,
    KoiFooter,
    Navbar,
    Zendesk
  },
  mounted() {
    log("component (terms) mounted");
  }
};
</script>

<style module>
.sub {
  padding: 0 8vw 4vw;
  text-align: left;
  & h6 {
    border-top: 1px solid #cbcbcb;
    font-size: 1.6rem;
    line-height: 1.5;
    margin: 3.6rem 0 0;
    padding: 3.6rem 0 2rem;
    &:first-child {
      border-top-color: transparent;
      margin-top: 2rem;
    }
  }
  & p,
  & li {
    font-size: 1.1rem;
    line-height: 1.5;
    text-align: justify;
  }
  & a {
    color: #690000;
  }
  &.xs,
  &.sm {
    & h6 {
      &:first-child {
        padding-top: 0;
      }
    }
  }
  &.md {
  }
  &.lg {
  }
  &.xl {
  }
}
.time {
  font-style: italic;
}
</style>
